import React, {Suspense, useEffect, useRef, useState} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {useParams} from "react-router-dom";
import TopNav1 from "../../Landing/TopNav";
import Header1 from "../../Landing/Header";
import TopNav from "./TopNavNew";
import Header from "./Header";
import Countdown from "react-countdown";
import Carousel from "react-bootstrap/Carousel";
import ReactWhatsapp from "react-whatsapp";

function Bids2(){
    let {id} = useParams()
    const [bids, setBids]=useState([])
    const [auctionID, setAuctionID]=useState(localStorage.getItem('auction_id'))
    const [salvage, setSalvage]=useState("")
    const [images, setImages]=useState([])
    const[bidAmount, setBidAmount]=useState("")
    const[highestBid, setHighestBid]=useState("")
    const[success, setSuccess]=useState(false)
    const[exists, setExists]=useState("")
    const[auctionCategory, setauctionCategory]=useState("")
    const[auction, setAuction]=useState("")
    const[auctionDate, setAuctionDate]=useState([])
    const[loading, setLoading]=useState(true)
    const[lowbid, setLowBid]=useState(false)
    const[timeup, setTimeUp]=useState(false)
    const[bonustime, setBonusTime]=useState(0)
    const[bonust, setBonusT]=useState("")
    const[auctionEndTime, setAuctionEndTime]=useState(localStorage.getItem('auctionendtime'))
    const [showBonus, setShowBonus]=useState(false)
    const [addedBonus, setAddedBonus]=useState(0)
    const [highestCount, setHighestCount]=useState(false) //number of salvages the user is the highest bidder
    const [showStrTime, setShowStrTime]=useState(false)
    const[userBid, setUserBid]=useState("")
    const[highestCountError, setHighestCountError]=useState(false)
    const [formValue, setFormValue] = useState({
        bidAmount: "",
    });
    const [bonustimeDb, setBonusTimeDb]=useState([])
    //Get Salvage Details
    const getSalvageDetails = async () => {
        const response = await axios (buildAPIUrl(`salvage-details/${id}`))
            setSalvage(response.data)
            setImages(response.data.images)
    }
    //Get highest bid
    function getMax(arr, prop) {
        var max;
        for (var i=0 ; i<arr.length ; i++) {
            if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
                max = arr[i];
        }
        return max;
    }
    //Get salvage bids
    const getItems = async () => {
        try {
            const response = await axios(buildAPIUrl(`get-bids/${id}/${localStorage.getItem('auction_id')}/${localStorage.getItem('item')}`));
            const data = response.data;
            setBids(data.bids);
            setHighestCount(data.highestcount);
            setHighestBid(data.highestbid);
            setUserBid(data.userbid);

            const bonusTime = data.bonustime;
            localStorage.setItem('auctionbonustime', bonusTime);

            if (!bonusTime || bonusTime === "null") {
                if (new Date().getTime() > new Date(localStorage.getItem('auctionendtime')).getTime()) {
                    localStorage.removeItem('setbonustime');
                    localStorage.removeItem('auctionbonustime');
                    window.location.assign('/auction-ended');
                }
            } else {
                if (new Date().getTime() > new Date(bonusTime).getTime()) {
                    localStorage.removeItem('setbonustime');
                    localStorage.removeItem('auctionbonustime');
                    window.location.assign('/auction-ended');
                }
            }
        } catch (error) {
            console.error("Error fetching bids:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect( ()=>{
        const response1  = axios.get(buildAPIUrl(`check-if-user-exists/${localStorage.getItem('item')}/${auctionID}`)).then((res)=>{
            console.log(res.data)
            localStorage.setItem('numberofitems', res.data.items.items)
            setExists(res.data.items)
        })

        //Get salvage details should be removed
        getSalvageDetails()
        getItems()
        setauctionCategory(parseInt(localStorage.getItem('auctioncategory')))
        const intervalId = setInterval(getItems, 10000); // Poll every 10 seconds
        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [])
   const handleInput = (e)=> {
       setBidAmount(e.target.value)
    }
    const handleAddBids = async (e) => {
        e.preventDefault();
        const auctionId = localStorage.getItem('auction_id');
        const itemId = localStorage.getItem('item');
        const numberOfItems = parseInt(localStorage.getItem('numberofitems'));

        try {
            const response = await axios(buildAPIUrl(`get-bids/${id}/${auctionId}/${itemId}`));
            const highestCount = response.data.highestbidcount;
            setHighestCount(highestCount);

            const bonusTime = localStorage.getItem('auctionbonustime');
            const endTime = new Date(localStorage.getItem('auctionendtime')).getTime();
            const now = new Date().getTime();

            if (bonusTime) {
                if (now > new Date(bonusTime).getTime()) {
                    handleAuctionClosed();
                    return;
                }

                if (highestCount < numberOfItems) {
                    await handleBidSubmission();
                } else {
                    showHighestCountError();
                }
            } else {
                if (now > endTime) {
                    if (!localStorage.getItem('auctionbonustime')) {
                        setShowBonus(true);
                    } else {
                        handleAuctionClosed();
                    }
                } else {
                    await handleBidSubmission();
                }
            }
        } catch (error) {
            console.error("An error occurred while processing bids:", error);
        }
    };

    const handleAuctionClosed = () => {
        setTimeUp(true);
        setTimeout(() => {
            localStorage.removeItem('auctionendtime');
            localStorage.removeItem('auctionbonustime');
            window.location.assign("/auction-ended");
        }, 3000);
    };

    const handleBidSubmission = async () => {
        if (bidAmount % 1000 === 0 && bidAmount > highestBid) {
            document.getElementById('submit-button').innerHTML = 'Submitting...';
            try {
                const res = await axios.post(buildAPIUrl('save-bid'), {
                    salvage_id: id,
                    auction_id: auctionID,
                    bidAmount: bidAmount,
                    bonustimestatus: localStorage.getItem('setbonustime'),
                    auctionendtime: auctionEndTime,
                    name: `${localStorage.getItem('first_name')} ${localStorage.getItem('last_name')}`,
                    email: localStorage.getItem('email'),
                    user_id: localStorage.getItem('item')
                });

                if (res.data.addbonus === "1" && res.data.bonustimes < 3) {
                    const newEndTime = new Date(res.data.endTime + 1000 * 60 * 5);
                    localStorage.setItem('auctionbonustime', newEndTime);
                    console.log(newEndTime);
                    setAddedBonus(5);
                }

                setBidAmount(" ");
                setSuccess(true);
                setTimeout(() => setSuccess(false), 3000);
            } catch (err) {
                console.error("Bid submission error:", err);
            } finally {
                document.getElementById('submit-button').innerHTML = 'Submit';
            }
        } else {
            setLowBid(true);
            setTimeout(() => setLowBid(false), 3000);
        }
    };

    const showHighestCountError = () => {
        setHighestCountError(true);
        setTimeout(() => setHighestCountError(false), 5000);
    };


    const  handleLogin = () => {
        window.location.assign('/login')
    }
    return(
        <>
            <>
                <Suspense fallback={<div>Loading...</div>}>
                    {
                        !localStorage.getItem('item')

                            ?  <>
                                <TopNav1 />
                                <Header1 />
                            </>:
                            <>
                                <TopNav />
                                <Header />
                            </>

                    }
                    <div className="container-fluid mt-5 item-details">
                        <div className="container">

                            <div className="row mt-5">
                                <div className="col-md-6 details-img">
                                    <div className="container-fluid p-0">
                                        <Carousel>
                                            {
                                                images.map((image, key) => (
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                            alt="First slide"
                                                        />

                                                    </Carousel.Item>
                                                    /* <div className={key === 0 ? 'active carousel-item' : 'carousel-item' }>
                                                         <img className="d-block w-100" src= alt="First slide" />
                                                     </div>*/
                                                ))
                                            }

                                        </Carousel>
                                    </div>
                                    <div className="container-fluid mt-2 ">
                                        <div className={"row"}>
                                            {
                                                images.map((image, key) => (
                                                    <div className={"col-md-3 px-1"}>
                                                        <img
                                                            className="d-block w-100"
                                                            src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                            alt="First slide" style={{height:"150px"}}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row top-details d-flex align-items-center">
                                        <div className="col-md-12">
                                            <div className={"row d-flex align-items-center"}>
                                                <div className={"col-md-4"}>
                                                    <h3 className={"m-0 p-0"}>{salvage.make + " " + salvage.model}</h3>
                                                </div>
                                                <div className="col-md-8 d-flex justify-content-end">
                                                    {
                                                        bids.length > 0 ?
                                                            <span style={{background:"rgba(224,224,224,0.68)", padding:"10px 20px", borderRadius:"5px"}}>
                                                                <h3 className={"m-0 p-0"} style={{color:"#f38e24"}}>
                                                                    <span style={{fontSize:"14px", fontWeight:"400"}}>Highest Bid. </span>KES {(+highestBid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            </h3>

                                                            </span>
                                                            :
                                                            <h6 className="text-center">There are no bids yet</h6>


                                                    }

                                                </div>
                                            </div>
                                            <div className={"container-fluid p-0"}>
                                                <ul className={"p-0"}>
                                                    <li>
                                                        <h6 className={"light"}> Registration Number: {salvage.regNo}</h6>

                                                    </li>


                                                </ul>

                                            </div>
                                        </div>


                                    </div>
                                    {
                                        localStorage.getItem('auctionbonustime')==="null" || !localStorage.getItem('auctionbonustime')  ? <>

                                        </> : <>
                                            <>
                                                <h5 className={"text-danger"}> Bonus Time:

                                                    <span style={{marginLeft:".5rem"}}>
                                                    <Countdown date={new Date().getTime() - Date.now() + (new Date(localStorage.getItem('auctionbonustime')).getTime())} />
                                                </span></h5>
                                            </>
                                        </>
                                    }

                                    <div className="">
                                        <div>
                                            <div className={timeup ? "alert alert-danger" : "d-none"}>
                                                Time is up. Logging you out...
                                            </div>
                                            <div className={lowbid ? "alert alert-danger" : "d-none"}>
                                                Please increase your bid. It cannot be lower than or equal to {highestBid} and must be divisible by 1000
                                            </div>
                                            {
                                                highestCountError ? <>
                                                    <p className={"alert alert-danger"}>
                                                        You have maxed out your bidding opportunities...
                                                    </p>
                                                </> : <></>
                                            }
                                            <div>
                                            </div>
                                        </div>

                                    </div>
                                    <form onSubmit={handleAddBids} className={"mt-3 mb-5"}>
                                        <h6 className={success ? "alert alert-success" : "d-none"}
                                            style={{fontSize: "12px", fontWeight: "400"}}>Bid Placed
                                            successfully</h6>
                                        <div className="mb-3 mt-2">
                                            <div className={"row d-flex align-items-center"}>
                                                <div className={"col-md-8 d-flex align-items-center"}>
                                                    <input type="text" name="bidAmount" className="form-control"
                                                           onChange={handleInput} value={bidAmount}
                                                           placeholder="Place Bid"/>
                                                </div>
                                                <div className={"col-md-4 d-flex align-items-center"}>
                                                    {
                                                        loading ? <>

                                                            </> :
                                                            <>
                                                                <div className="">
                                                                    {
                                                                        localStorage.getItem('uniq') === null ?
                                                                            <a onClick={handleLogin}
                                                                               className="btn" style={{width:"100%", borderRadius:"0"}}>Login to start
                                                                                bidding</a> :
                                                                            <>
                                                                            {
                                                                                localStorage.getItem('auctionCategory')==="1" ? <>
                                                                                    <>
                                                                                        {
                                                                                            userBid === "No" ? <>
                                                                                                <p className={"text-danger"}>You are not authorized to bid</p>
                                                                                            </> : <>
                                                                                                <button id="submit-button"
                                                                                                        className="btn  ">Submit
                                                                                                    Bid</button>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                </> : <>
                                                                                    <>
                                                                                        <button id="submit-button"
                                                                                                className="btn  ">Submit
                                                                                            Bid</button>
                                                                                    </>
                                                                                </>
                                                                            }

                                                                            </>

                                                                    }
                                                                </div>

                                                            </>
                                                    }
                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div className={"container-fluid auction-info"}>
                                        <div className={"row"}>
                                            <div className={"col-md-4"}>
                                                <h5>
                                                    <Countdown date={new Date().getTime() - Date.now() + (new Date(localStorage.getItem('auctionendtime')).getTime())} />
                                                </h5>
                                                <h6>
                                                    Time left
                                                </h6>
                                            </div>
                                            <div className={"col-md-4"}>
                                                <h5>
                                                    {bids.length}
                                                </h5>
                                                <h6>
                                                   Number of Bids
                                                </h6>
                                            </div>
                                            <div className={"col-md-4"}>
                                                <h5>
                                                    <>
                                                        <h5 className={"m-0 p-0"} >
                                                            {(+highestBid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        </h5>
                                                    </>
                                                </h5>
                                                <h6>
                                                    Highest Bid
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" m-0 mt-2 p-0 mb-3">
                                        <div className="card-body">
                                            <table
                                                className="table m-0">
                                                <tr>
                                                    <td>Make</td>
                                                    <td>{salvage.make}</td>

                                                </tr>
                                                <tr>
                                                    <td>Model</td>
                                                    <td>{salvage.model}</td>

                                                </tr>
                                                <tr>
                                                    <td>Location</td>
                                                    <td>{salvage.location}</td>

                                                </tr>
                                                <tr>
                                                    <td>Reserve Price</td>
                                                    <td>KES {(+salvage.reservePrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>

                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <h4>Now: {(new Date(this.state.auctionEndTime).getTime() - new Date().getTime())/1000}</h4>*/}

                                </div>
                            </div>
                        </div>
                    </div>

                </Suspense>
            </>

        </>
    )
}

export default Bids2