import React, {Component} from "react";
import {FaCar, FaCarCrash} from "react-icons/fa";
import {HiOutlineHome} from "react-icons/hi";
import {Link} from "react-router-dom";
import Countdown from "react-countdown";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Typed from "react-typed";
import MediaQuery from "react-responsive";

class TopSection extends Component{
    state = {
        auctiondate: []
    }
    componentDidMount() {
        const response = axios.get(buildAPIUrl('next-auction')).then((res)=>{
            this.setState({
                auctiondate:res.data.auctions
            })
        })
    }

    handleUrl = (url) => {
        window.location.assign(url)
    }
    render() {
        return (
            <>
                <MediaQuery minWidth={1200}>
                    <div className="container-fluid p-0 m-0 landing d-flex align-items-center justify-content-center ">
                        <div className="row d-flex  justify-content-center w-100 align-items-center">
                            <div className="col-md-4 px-5">
                                {/*  <h1>Find Your Next <span className="text-danger">Match</span></h1>*/}
                                <h3 style={{color:"#e34b1d", fontSize:"50px", lineHeight:"65px"}}> <span style={{color:"#070707"}}>
                                    LOOKING FOR
                                </span>  <br/>
                                    <Typed
                                        strings={["PROPERTIES?", "SALVAGES?", "CARS?"]}
                                        typeSpeed={100}
                                        backSpeed={100}
                                        loop
                                /></h3>
                                <p className={"mt-3"} style={{color:"#6B728E", fontSize:"14px"}}>
                                    Reizen auction gives you access to properties, cars and salvages which are on sale or being auctioned.
                                    Our items are genuine and come from carefully selected insurance companies and sellers.

                                </p>
                                <div className={"tabs-section"}>
                                    {/*<div className="col-md-12 d-flex justify-content-center mt-3">   <button className="btn salvages" onClick={()=>this.handleUrl("/future-auctions")}>   Auctions</button> </div>
                                    <div className="col-md-12 d-flex justify-content-center mt-3"><button className="btn cars " onClick={()=>this.handleUrl("/cars")}>  Cars and Salvage Listings</button></div>*/}
                                    <div className="col-md-8 d-flex justify-content-center mt-3"><button className="btn property  " onClick={()=>this.handleUrl("/properties")}> Explore our Properties</button></div>
                                </div>

                            </div>
                            <div className="col-md-8 image-section text-center d-flex justify-content-center p-0 ">
                                <div className="col-md-12 container-fluid">
                                    {
                                        this.state.auctiondate ?
                                            this.state.auctiondate.slice(0,1).map((auction)=>(
                                                <>

                                                    {
                                                        new Date(auction.auctionStartTime).getTime() < new Date().getTime()  ? "" :  <h3 style={{color:"wheat"}}>Next auction starts in:   <Countdown date={Date.now() + (new Date(auction.auctionStartTime).getTime() - new Date().getTime())} /> </h3>
                                                    }

                                                </>

                                            ))
                                            : ""
                                    }

                                </div>

                            </div>
                        </div>


                    </div>
                </MediaQuery>
                <MediaQuery minWidth={461} maxWidth={1200}>
                    <div className="container-fluid p-0 m-0 landing d-flex align-items-center justify-content-center ">
                        <div className="row d-flex  justify-content-center w-100 align-items-center">
                            <div className="col-md-4 px-5">
                                {/*  <h1>Find Your Next <span className="text-danger">Match</span></h1>*/}
                                <h3 style={{color:"#e34b1d", fontSize:"50px", lineHeight:"65px"}}> <span style={{color:"#070707"}}>
                                    LOOKING FOR
                                </span>  <br/>
                                    <Typed
                                        strings={["PROPERTIES?", "SALVAGES?", "CARS?"]}
                                        typeSpeed={100}
                                        backSpeed={100}
                                        loop
                                    /></h3>
                                <p className={"mt-3"} style={{color:"#6B728E", fontSize:"18px"}}>
                                    Reizen auction gives you access to properties, cars and salvages which are on sale or being auctioned.
                                    Our items are genuine and come from carefully selected insurance companies and sellers.

                                </p>
                                <div className={"tabs-section"}>
                                    {/*<div className="col-md-12 d-flex justify-content-center mt-3">   <button className="btn salvages" onClick={()=>this.handleUrl("/future-auctions")}>   Auctions</button> </div>
                                    <div className="col-md-12 d-flex justify-content-center mt-3"><button className="btn cars " onClick={()=>this.handleUrl("/cars")}>  Cars and Salvage Listings</button></div>*/}
                                    <div className="col-md-8 d-flex justify-content-center mt-3"><button className="btn property  " onClick={()=>this.handleUrl("/properties")}> Explore our Properties</button></div>
                                </div>

                            </div>
                            <div className="col-md-8 image-section text-center d-flex justify-content-center p-0 ">
                                <div className="col-md-12 container-fluid">
                                    {
                                        this.state.auctiondate ?
                                            this.state.auctiondate.slice(0,1).map((auction)=>(
                                                <>

                                                    {
                                                        new Date(auction.auctionStartTime).getTime() < new Date().getTime()  ? "" :  <h3 style={{color:"wheat"}}>Next auction starts in:   <Countdown date={Date.now() + (new Date(auction.auctionStartTime).getTime() - new Date().getTime())} /> </h3>
                                                    }

                                                </>

                                            ))
                                            : ""
                                    }

                                </div>

                            </div>
                        </div>


                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"mobile"}>
                        <div className="container  m-0 landing d-flex align-items-center justify-content-center ">

                            <div className="row d-flex  w-100 align-items-center">
                                <div className="col-md-4 mt-5 mb-5">
                                    {/*  <h1>Find Your Next <span className="text-danger">Match</span></h1>*/}
                                    <h3 style={{color:"#e34b1d", fontSize:"30px", lineHeight:"40px"}}> <span style={{color:"#070707"}}>
                                    LOOKING FOR
                                </span>  <br/>
                                        <Typed
                                            strings={["PROPERTIES?", "SALVAGES?", "CARS?"]}
                                            typeSpeed={100}
                                            backSpeed={100}
                                            loop
                                        /></h3>
                                    <p className={"mt-3"} style={{color:"#6B728E", fontSize:"18px"}}>
                                        Reizen auction gives you access to properties, cars and salvages which are on sale or being auctioned.
                                        Our items are genuine and come from carefully selected insurance companies and sellers.

                                    </p>
                                    {
                                        this.state.auctiondate ?
                                            this.state.auctiondate.slice(0,1).map((auction)=>(
                                                <>

                                                    {
                                                        new Date(auction.auctionStartTime).getTime() < new Date().getTime()  ? "" :  <h3 style={{color:"wheat"}}>Next auction starts in:   <Countdown date={Date.now() + (new Date(auction.auctionStartTime).getTime() - new Date().getTime())} /> </h3>
                                                    }

                                                </>

                                            ))
                                            : ""
                                    }

                                    <div className={"tabs-section"}>
                                        {/*<div className="col-md-12 d-flex justify-content-center mt-3">   <button className="btn salvages" onClick={()=>this.handleUrl("/future-auctions")}>   Auctions</button> </div>
                                    <div className="col-md-12 d-flex justify-content-center mt-3"><button className="btn cars " onClick={()=>this.handleUrl("/cars")}>  Cars and Salvage Listings</button></div>*/}
                                        <div className="col-md-8 d-flex justify-content-center mt-3"><button className="btn property  " onClick={()=>this.handleUrl("/properties")}> Explore our Properties</button></div>
                                    </div>

                                </div>

                            </div>


                        </div>
                    </div>

                </MediaQuery>
            </>

        )
    }
}

export default TopSection