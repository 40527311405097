import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import TopNav from "./TopNavNew";
import Header from "./Header";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Logo from "../../../assets/reizen.png"
import ReactToPrint from "react-to-print";
 class AuctionDetails extends Component{

     state = {
         active:"",
         salvages:[],
         bids:[],
         auctionid: this.props.match.params.id,
         auction:"",
         auctionDetails:"",
         auctionType:null,
         deleted:false
     }
     componentDidMount(){
             axios.get(buildAPIUrl(`get-salvages-in-auction/${this.state.auctionid}`)).then((res1)=>{
                 this.setState({
                     salvages: res1.data.salvages,
                     auction:res1.data.auction_id,
                     auctionDetails: res1.data.auction_details,
                     auctionType:res1.data.auction_details.auctionType
                 })

             })

     }
handlePrint = (e) => {
     const doc = new jsPDF()
    autoTable(doc, { html: '#salvages', body:[[{content: 'Reizen Auction Report', colSpan: 2, rowSpan: 2, styles: { halign: 'center' } }]], styles:{cellWidth: 45}, includeHiddenHtml:false})
     doc.save(new Date().toLocaleDateString())
 }
 handleDeleteBid = (bidid) =>{
         const response = axios.post(buildAPIUrl(`delete-bid/${bidid}`)).then((response)=>{
             this.setState({
                 deleted:true
             })
             setTimeout(()=>{
                 this.setState({
                     deleted:false
                 })

             }, 3000)
             axios.get(buildAPIUrl(`get-salvages-in-auction/${this.state.auctionid}`)).then((res1)=>{
                 this.setState({
                     salvages: res1.data.salvages,
                     auction:res1.data.auction_id,
                     auctionDetails: res1.data.auction_details,
                     auctionType:res1.data.auction_details.auctionType
                 })

             })
         }).catch((error)=>{

         })
 }

     render() {
         const ref = React.createRef();
         const options = {
             orientation: 'landscape',
         };
         const ids = ['1']
         const filtereditems = this.state.bids.filter(result => result.auction_id===this.props.match.params.id)
         return(
             <>
                 <TopNav />
                 <Header />

                 <div className="container mt-5 auctions">
                     <div className="row">

                         <div className="col-md-12">
                             <div className="card">
                                 <div className="card-header">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-6">  <h6 className="mt-3">Closed Auction</h6></div>
                                            <div className="col-md-6 d-flex justify-content-end">
                                               {/* <Pdf targetRef={ref} options={options} filename="code-example.pdf">
                                                    {({ toPdf }) => <button className="btn btn-succes"  onClick={toPdf}>Generate Pdf</button>}
                                                </Pdf>*/}
                                               {/* <button onClick={() => this.handlePrint()}>Download Report</button>*/}

                                                <ReactToPrint
                                                    trigger={() => {
                                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                        // to the root node of the returned component as it will be overwritten.
                                                        return <button className="btn btn-success">Print</button>;
                                                    }}
                                                    content={() => this.componentRef}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                 </div>
                                 <div className="card-body p-0 m-0 " >
                                     {
                                         this.state.auctionType===1 ?  <div className="container pt-5" ref={el => (this.componentRef = el)} >
                                             <div className="row mt-5">
                                                 <div className="col-md-12">
                                                     <h6>Auction Date: {new Date(this.state.auctionDetails.auctionDate).toLocaleDateString()}</h6>
                                                     <h6>Auction Start Time: {this.state.auctionDetails.auctionStartTime} </h6>
                                                     <h6>Auction End Time: {this.state.auctionDetails.auctionEndTime} </h6>
                                                     <h6>Print Date: {new Date().toLocaleDateString()}</h6>
                                                 </div>
                                                 <div className="col-md-4">
                                                     <img src={Logo} style={{width:"200px", height:"100px"}}/>
                                                 </div>
                                             </div>
                                                 <div className={this.state.deleted ? "alert alert-danger" : "d-none"}>
                                                     Deleting bid...
                                                 </div>
                                             <table  className="table table-borderless" id="salvages">
                                                 <thead>
                                                 <tr className="options-table-header">
                                                     <th>Reg Number</th>
                                                     <th>Make</th>
                                                     <th>Model</th>
                                                     <th>Bids</th>

                                                 </tr>
                                                 </thead>
                                                 <tbody>
                                                 {
                                                     this.state.salvages ? <>

                                                         {
                                                             this.state.salvages.map((salavge)=>(
                                                                 <tr>
                                                                     <td>{salavge.regNo}</td>
                                                                     <td>{salavge.make}</td>
                                                                     <td>{salavge.model}</td>
                                                                     <td>{
                                                                         salavge.bids ? <ol>
                                                                             {
                                                                                 salavge.bids.filter(result => result.auction_id==this.state.auction.toLocaleString()).map((bid)=>(
                                                                                     <>
                                                                                         <li className="bid-list"><span> {bid.name} </span> <span
                                                                                             className="text-black" style={{
                                                                                             fontWeight: "700",
                                                                                             color: "green"
                                                                                         }}> {bid.bidAmount} </span>
                                                                                             <h6> <i style={{fontSize: "12px"}}>{new Date(bid.created_at).toLocaleDateString()} {new Date(bid.created_at).toLocaleTimeString()}</i>
                                                                                                 {
                                                                                                     bid.status===1 ? <>
                                                                                                         <span className={"text-danger"}>Flagged by Admin</span>
                                                                                                     </> : <>
                                                                                                         <span style={{cursor:"pointer"}} onClick={()=>this.handleDeleteBid(bid.id)} className={"text-danger"}>Flag Bid</span>
                                                                                                     </>
                                                                                                 }

                                                                                             </h6>
                                                                                            {/* <p style={{cursor:"pointer"}} className={"text-danger"} onClick={()=>this.handleDeleteBid(bid.id)}>
                                                                                                 Delete Bid
                                                                                             </p>*/}
                                                                                         </li>

                                                                                     </>


                                                                                 ))
                                                                             }
                                                                         </ol> : <p>There are no bids</p>
                                                                     }</td>
                                                                 </tr>
                                                             ))
                                                         }



                                                     </> : <p>There is no active auction running</p>
                                                 }
                                                 <div  className="d-none">

                                                     <table  className="d-none">
                                                         <thead>
                                                         <tr className="options-table-header">
                                                             <th style={{width:"30%"}}>Reg Number</th>
                                                             <th>Make</th>
                                                             <th>Model</th>
                                                             <th>Bids</th>
                                                         </tr>
                                                         </thead>
                                                         <tbody>
                                                         {
                                                             this.state.salvages ? <>

                                                                 {
                                                                     this.state.salvages.map((salavge)=>(
                                                                         <tr>
                                                                             <td>{salavge.regNo}</td>
                                                                             <td>{salavge.make}</td>
                                                                             <td>{salavge.model}</td>
                                                                             <td>{
                                                                                 salavge.bids ? <ol>
                                                                                     {
                                                                                         salavge.bids.filter(result => result.auction_id===this.state.auction.toLocaleString()).map((bid)=>(
                                                                                             <>
                                                                                                 <h6 style={{fontSize:"12px"}}>{bid.name} {bid.bidAmount} { new Date(bid.created_at).toLocaleDateString()} {bid.auction_id}</h6>
                                                                                                 <br/>
                                                                                                 <br/>
                                                                                             </>


                                                                                         ))
                                                                                     }
                                                                                 </ol> : <p>There are no bids</p>
                                                                             }</td>
                                                                         </tr>
                                                                     ))
                                                                 }



                                                             </> : <p>There is no active auction running</p>
                                                         }
                                                         </tbody>
                                                     </table>
                                                 </div>

                                                 </tbody>
                                             </table>

                                         </div>
                                             :  <div className="container pt-5" ref={el => (this.componentRef = el)} >
                                                 <div className="row mt-5">
                                                     <div className="col-md-10">
                                                         <h6>Auction Date: {new Date(this.state.auctionDetails.auctionDate).toLocaleDateString()} </h6>
                                                         <h6>Auction Start Time: {this.state.auctionDetails.auctionStartTime} </h6>
                                                         <h6>Auction End Time: {this.state.auctionDetails.auctionEndTime} </h6>
                                                         <h6>Print Date: {new Date().toLocaleDateString()}</h6>
                                                     </div>
                                                     <div className="col-md-2">
                                                         <img src={Logo} style={{width:"200px", height:"100px"}}/>
                                                     </div>
                                                 </div>

                                                 <table  className="table table-borderless" id="salvages">
                                                     <thead>
                                                     <tr className="options-table-header">
                                                         <th>Property name</th>
                                                         <th>Location</th>
                                                         <th>Size</th>
                                                         <th>Bids</th>
                                                     </tr>
                                                     </thead>
                                                     <tbody>
                                                     {
                                                         this.state.salvages ? <>

                                                             {
                                                                 this.state.salvages.map((salavge)=>(
                                                                     <tr>
                                                                         <td>{salavge.propertyName}</td>
                                                                         <td>{salavge.location}</td>
                                                                         <td>{salavge.size}</td>
                                                                         <td>{
                                                                             salavge.propertybids ? <ol>
                                                                                 {
                                                                                     salavge.propertybids.filter(result => result.auction_id==this.state.auction.toLocaleString()).map((bid)=>(
                                                                                         <>
                                                                                             <li className="bid-list"><span> {bid.name} </span> <span
                                                                                                 className="text-black" style={{
                                                                                                 fontWeight: "700",
                                                                                                 color: "green"
                                                                                             }}> {bid.bidAmount} </span>
                                                                                                 <h6> <i style={{fontSize: "12px"}}>{new Date(bid.created_at).toLocaleDateString()} {new Date(bid.created_at).toLocaleTimeString()}</i></h6>
                                                                                             </li>

                                                                                         </>


                                                                                     ))
                                                                                 }
                                                                             </ol> : <p>There are no bids</p>
                                                                         }</td>
                                                                     </tr>
                                                                 ))
                                                             }



                                                         </> : <p>There is no active auction running</p>
                                                     }
                                                     <div  className="d-none">

                                                         <table  className="d-none">
                                                             <thead>
                                                             <tr className="options-table-header">
                                                                 <th style={{width:"30%"}}>Reg Number</th>
                                                                 <th>Make</th>
                                                                 <th>Model</th>
                                                                 <th>Bids</th>
                                                             </tr>
                                                             </thead>
                                                             <tbody>
                                                             {
                                                                 this.state.salvages ? <>

                                                                     {
                                                                         this.state.salvages.map((salavge)=>(
                                                                             <tr>
                                                                                 <td>{salavge.regNo}</td>
                                                                                 <td>{salavge.make}</td>
                                                                                 <td>{salavge.model}</td>
                                                                                 <td>{
                                                                                     salavge.bids ? <ol>
                                                                                         {
                                                                                             salavge.bids.filter(result => result.auction_id===this.state.auction.toLocaleString()).map((bid)=>(
                                                                                                 <>
                                                                                                     <h6 style={{fontSize:"12px"}}>{bid.name} {bid.bidAmount} { new Date(bid.created_at).toLocaleDateString()} {bid.auction_id}</h6>
                                                                                                     <br/>
                                                                                                     <br/>
                                                                                                 </>


                                                                                             ))
                                                                                         }

                                                                                     </ol> : <p>There are no bids</p>
                                                                                 }</td>
                                                                             </tr>
                                                                         ))
                                                                     }



                                                                 </> : <p>There is no active auction running</p>
                                                             }
                                                             </tbody>
                                                         </table>
                                                     </div>

                                                     </tbody>
                                                 </table>

                                             </div>

                                     }

                                 </div>

                             </div>
                         </div>
                     </div>
                 </div>
             </>
         )
     }
 }
 export default AuctionDetails